import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
//import Markdown from "react-markdown"

const NewsItem = article => {
  //console.log("Article:", article)
  //console.log("Article - Headings:", article.headings.title)
  //console.log("Article - Headings - title:", article.headings.title)
  //console.log("Article - Headings - description:", article.headings.description)

  //console.log("Slug:", article.category.slug)
  //console.log("Slug:", article.category.slug)
  return (
    <div>
      <header>
        
          <Link
            to={`/article/${article.slug}`}
            className="uk-link-reset  hover-underline-animation-dark uk-margin-small-bottom"
          >

           <h4 className="uk-margin-remove green-grad uk-text-bold">
            {article.cardTitle}
          </h4>
          </Link>
        

        <div className="newsItemSubHeading">
          <h5
          className="uk-article-meta uk-text-bold"
          style={{ color: "#617F7F" }}
        >
          Written by {article.author.name} on{" "}
          <span
            className="uk-text-small uk-text-muted"
            style={{ color: "#617F7F" }}
          >
            <Moment style={{ color: "#617F7F" }} format="MMM Do YYYY">
              {article.publishedAt}
            </Moment>
          </span>
          . Posted in{" "}
          <Link
            to={`/category/${article.category.slug}`}
            style={{ color: "#617F7F" }}
          >
            {article.category.name}
          </Link>{" "}
          | <span uk-icon="icon: future" /> Takes {/* article.ReadTime */} 5 min
          reading.
        </h5>
        </div>
        
      </header>
      <figure className="uk-margin-remove-bottom">
        {article?.cover && (
          <GatsbyImage
            image={getImage(article.cover.localFile)}
            alt={article.headings.title}
            title={article.headings.title}
            style={{ width: "100%" }}
          />
        )}
        <figcaption className="uk-text-center uk-margin-small-top uk-margin-small-bottom">
          <h5 className="green-grad uk-margin-remove-bottom" >{article.imageCaption}</h5>
        </figcaption>
      </figure>
      
      {/* <Markdown
        className="uk-padding-remove-bottom"
        children={article.longDesc}
      /> */}

      <div className="uk-margin-small-bottom">{article.longDesc}</div>

      <div className="uk-flex">
        <div>
          <Link
            to={`/article/${article.slug}`}
            toggle="button"
            className="uk-button uk-button-default uk-button-small uk-text-small uk-text-capitalize"
            style={{ borderColor: "#617F7F", color: "#617F7F", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
          >
            READ MORE
          </Link>
        </div>
      </div>

      <hr style={{ 
        backgroundImage: "linear-gradient(to right, transparent 0%, #617F7F 0%)",
        backgroundSize:"34px 100%",
        height: "3px",
        border: "none"
      }} />
    </div>
  )
}

export default NewsItem
