import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Categories = () => {
  const { allStrapiCategory } = useStaticQuery(graphql`
    query {
      allStrapiCategory(sort: { fields: sequence, order: ASC }) {
        nodes {
          name
          slug
          # description
          # sequence
        }
      }
    }
  `)

  const categories = allStrapiCategory

  return (
    <React.Fragment>
      <h4 className="uk-heading-line uk-text-bold">
        <span className="green-grad">Categories</span>
      </h4>
      <ul className="uk-list uk-list-striped">
        {categories.nodes.map((category, i) => {
          //console.log("Category:", category)
          return (
            <li key={`category__${category.slug}`}>
              <Link 
                to={`/category/${category.slug}`}
                className="hover-underline-animation-dark green-grad"
              >
                <h5 className="uk-margin-remove">
                  {category.name}
                </h5>
              </Link>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

export default Categories
