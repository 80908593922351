import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const NewsAdOverlay = ({ newsAdverts, categoryAdverts }) => {
  //console.log("newsAdverts:", newsAdverts)
  //console.log("categoryAdverts:", categoryAdverts)
  return (
    <div>
      {newsAdverts?.map((item, index) => {
        //console.log("item: ", item)
        return (
          <div key={index} className="uk-margin-medium-top">
            <h4
              className="uk-heading-line uk-text-bold"
            >
              <span className="green-grad">{item.title}</span>
            </h4>
            <div className="uk-inline uk-transition-toggle uk-padding-remove">
              {item?.image && (
                <GatsbyImage
                  image={getImage(item.image.localFile)}
                  alt={item.image.alternativeText}
                  title={item.image.alternativeText}
                  style={{ maxHeight: "100%" }}
                />
              )}
              <div className="uk-position-cover uk-overlay-primary uk-flex uk-flex-center uk-flex-middle uk-transition-fade">
                {/* Overlay content  */}
                <div className="uk-position-center uk-text-center">
                  {/* Add some animation for beauty */}
                  <div className="uk-transition-slide-top-small uk-transition-fade uk-margin-small-bottom">
                    <h3 className="uk-text-bold gradient-text-orange">
                      <span> {item.heading}</span>
                    </h3>
                  </div>

                  <div className="uk-transition-slide-right-small uk-transition-fade uk-margin-small-bottom uk-padding-small uk-text-left">
                    <p>{item.description}</p>
                  </div>

                  <div className="uk-transition-slide-bottom-small uk-transition-fade uk-margin-small-top">
                    {item.isInternal === true ? (
                      <Link
                        to={item.link}
                        alt={item.title}
                        toggle="button"
                        className="uk-button uk-button-default uk-button-small uk-text-small"
                        style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        {item.buttonName}
                      </Link>
                    ) : (
                      <a
                        href={item.link}
                        alt={item.title}
                        target="_blank"
                        rel="noopener noreferrer"
                        toggle="button"
                        className="uk-button uk-button-default uk-button-small uk-text-small"
                        style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        {item.buttonName}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}

      {categoryAdverts?.map((item, index) => {
        //console.log("item: ", item)
        return (
          <div key={index} className="uk-margin-medium-top">
            <h4 className="uk-heading-line uk-text-bold">
              <span className="green-grad">{item.title}</span>
            </h4>
            <div className="uk-inline uk-transition-toggle uk-padding-remove">
              {item?.image && (
                <GatsbyImage
                  image={getImage(item.image.localFile)}
                  alt={item.image.alternativeText}
                  title={item.image.alternativeText}
                  //style={{ maxHeight: "100%" }}
                />
              )}
              <div className="uk-position-cover uk-overlay-primary uk-flex uk-flex-center uk-flex-middle uk-transition-fade">
                {/* Overlay content  */}
                <div className="uk-position-center uk-text-center">
                  {/* Add some animation for beauty */}
                  <div className="uk-transition-slide-top-small uk-transition-fade uk-margin-small-bottom">
                    <h3 className="uk-text-bold gradient-text-orange">
                      <span> {item.heading}</span>
                    </h3>
                  </div>

                  <div className="uk-transition-slide-right-small uk-transition-fade uk-margin-small-bottom uk-padding-small uk-text-left">
                    <p>{item.description}</p>
                  </div>

                  <div className="uk-transition-slide-bottom-small uk-transition-fade uk-margin-small-top">
                    {item.isInternal === true ? (
                      <Link
                        to={item.link}
                        alt={item.title}
                        toggle="button"
                        className="uk-button uk-button-default uk-button-small uk-text-small"
                        style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        {item.buttonName}
                      </Link>
                    ) : (
                      <a
                        href={item.link}
                        alt={item.title}
                        target="_blank"
                        rel="noopener noreferrer"
                        toggle="button"
                        className="uk-button uk-button-default uk-button-small uk-text-small"
                        style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        {item.buttonName}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default NewsAdOverlay
