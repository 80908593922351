import React from "react"
import { Link } from "gatsby"

const NewsArchives = () => {
  return (
    <React.Fragment>
      <div className="uk-margin-medium-top">
        <h4 className="uk-heading-line uk-text-bold">
          <span className="green-grad">Archive</span>
        </h4>
        
        <ul className="uk-list">
          <li>
            <Link to="/" alt="March news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
                March <small>(2024)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="February news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              February <small>(2024)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="January news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              January <small>(2024)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="December news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              December <small>(2023)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="November news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              November <small>(2023)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="October news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              October <small>(2023)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="September news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              September <small>(2023)</small>
              </h5>
            </Link>
          </li>
          <li>
            <Link to="/" alt="August news archives" className="hover-underline-animation-dark green-grad">
              <h5 className="uk-margin-remove">
              August <small>(2023)</small>
              </h5>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default NewsArchives
