import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const RecentPosts = ({ filteredArticles }) => {
  return (
    <div className="">
      <h4 className="uk-heading-line uk-text-bold">
        <span className="green-grad">Recent Posts</span>
      </h4>

      {filteredArticles.map((article, index) => {
        //console.log(article)

        return (
          <div
            id="author-wrap"
            className="uk-container uk-container-xsmall uk-margin-small-bottom"
            key={`article__${article.slug}`}
          >
            <div className="uk-grid uk-flex uk-flex-middle" data-uk-grid="true">
              <div className="uk-width-auto">
                {article.author?.avatar && (
                  <GatsbyImage
                    image={getImage(article.author.avatar.localFile)}
                    alt={article.author.name}
                    title={article.author.name}
                    className="uk-border-circle"
                    style={{ width: 50, height: 50 }}
                  />
                )}
              </div>
              <div className="uk-width-expand">
                <Link
                  to={`/article/${article.slug}`}
                  alt="text"
                  className="hover-underline-animation-dark"
                >
                  <h5 className="uk-margin-remove uk-text-bold green-grad">
                    {article.cardTitle}
                  </h5>
                  
                </Link>
                <br />
                <span className="uk-text-small uk-text-muted">
                  Written by {article.author.name}
                </span>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RecentPosts
