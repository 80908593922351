import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Headings from "./headings"

const FeaturedArticle = ({ featuredArticle, title, description, bg = "uk-background-default"  }) => {
  //console.log("Featured Post: ", featuredArticle)

  return (
    <section className="uk-section uk-section-small uk-padding-remove">
      <div className="uk-container">
        <Headings
          title={title}
          description={description}
          bg={bg}
        />
        <div className="uk-height-large uk-cover-container uk-border-rounded uk-margin-medium-bottom">
          {featuredArticle?.image && (
            <GatsbyImage
              image={getImage(featuredArticle.image.localFile)}
              alt={featuredArticle.title}
              title={featuredArticle.title}
              //data-uk-cover="true"
              style={{ height: "100%", width: "100%"}}
            />
          )}
          <div className="uk-padding-small uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-dark uk-text-center">
            <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small ">
              <span
                style={{
                  color: "#FFFFFF",
                  textShadow: "2px 2px 4px #000000",
                  styleLetterSpacing: "0.2em",
                  fontSize: "0.725rem",
                }}
              >
                {featuredArticle.smallTitle}
              </span>
              <h2
                className="uk-margin-small-top uk-margin-small-bottom "
                style={{
                  textShadow: "2px 2px 4px #000000",
                  //fontSize: "0.725rem",
                }}
              >
                {featuredArticle.title}
              </h2>
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2rem",
                  width: "100%",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                {featuredArticle.description}
              </p>
              {featuredArticle.link.isInternal === true ? (
                <Link
                  to={featuredArticle.link.url}
                  alt={featuredArticle.link.name}
                  toggle="button"
                  className="uk-button uk-button-default uk-button-small uk-text-small"
                  style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px",  marginBottom: "20px"  }}
                >
                  GO TO ARTICLE
                </Link>
              ) : (
                <a
                  href={featuredArticle.link.url}
                  alt={featuredArticle.link.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  //toggle="button"
                  className="uk-button uk-button-default uk-button-small uk-text-small"
                  style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px",  marginBottom: "20px"  }}
                >
                  GO TO ARTICLE
                </a>
              )}

              <div className="uk-width-auto uk-padding-small">
                {featuredArticle?.banner && (
                  <GatsbyImage
                    image={getImage(featuredArticle.banner.localFile)}
                    alt={featuredArticle.banner.alternativeText}
                    title={featuredArticle.banner.alternativeText}
                    style={{ maxWidth: "180px" }}
                    //style={{ maxHeight: "180px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedArticle
