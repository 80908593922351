import React from "react"

import NewsArchives from "../components/news-archives"
import NewsItem from "../components/news-item"
import Categories from "./categories"
import NewsAdOverlay from "./news-ad-overlay"
import RecentPosts from "./recent-posts"

const NewsGrid = ({ articles, category, newsAdverts, categoryAdverts }) => {
  //console.log("articles:", articles)
  //console.log("Category:", category)
  //console.log("newsAdverts:", newsAdverts)
  //console.log("categoryAdverts:", categoryAdverts)

  const filteredArticles = articles.filter((article, index) => index < 4)
  //console.log("filteredArticle:", filteredArticles)

  return (
    <div className="uk-section uk-section-default uk-padding-remove uk-margin-medium-bottom">
      <div className="uk-container">
        <div className="uk-grid" data-uk-grid="true">
          <div className="uk-width-2-3@m">
            <h4 className="uk-heading-line uk-text-bold">
              <span className="green-grad">Latest {category} News</span>
            </h4>
            <div 
                  className=""
                  style={{ marginTop: "12px", marginBottom: "8px"}}
                >
                <span>
                  STSP-Sri Lanka understand that our donators and sponsors of the dogs we rescue and care for at our shelter, require more than simple updates. 
                  They crave knowledge that their generous contributions they kindly make for these dogs, are actually making a difference and reaching them. These 
                  articles address the topics you want to know about, providing practical information, on the day to day running of our shelter rescues carried out
                </span>
                
                <div style={{ marginTop: "12px", marginBottom: "12px",}}>
                  <h5 className="uk-margin-remove" >
                    <span>Thoughts from our directors, voluenteers & partners.</span>
                  </h5>
                </div>
                
                <span>
                  These articles are a testament to the dedication of 
                  our directors, volunteers, partners and most importantly you the public and our community on social media. We show the 
                  day to day struggle the dogs face with legitimate real-time rescues with regular updates on their situation, keeping you up-to-date, on how 
                  your precious donations are helping us help these dogs. We would like to say a special thank all our community, you are the bloodline of what we do.

                </span>            
              </div>
              <h4 className="uk-heading-line uk-text-bold uk-margin-remove" >
                <span className="green-grad">Articles</span>
              </h4>
              {/* <hr style={{ 
                backgroundImage: "linear-gradient(to right, transparent 0%, #617F7F 0%)",
                backgroundSize:"34px 100%",
                height: "3px",
                border: "none"
              }} /> */}
            <div className="uk-section uk-section-small uk-padding-remove-top uk-margin-small-top">
              {articles.map((article, index) => {
                //console.log(article.excerpt)
                return <NewsItem key={index} index={index} {...article} />
              })}
            </div>
          </div>
          <div className="uk-width-1-3@m">
            <RecentPosts filteredArticles={filteredArticles} />
            <Categories />
            <NewsAdOverlay
              newsAdverts={newsAdverts}
              categoryAdverts={categoryAdverts}
            />
            <NewsArchives />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsGrid
